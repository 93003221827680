import React, { Component } from "react";
import {Fragment} from "react";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { useAlert } from "react-alert";
import LazyLoad from "react-lazyload"
import Loading from "./loader";
import Home from "./Home";
import DemoTwo from "./Demon";
import ChangePassword from "./ChangePassword";
import message from "./images/message.png"







class Info extends Component {
  constructor(props) {
    super(props);
    this.OnSubmitForm = React.createRef();
    this.state = {issuers:[],
                  docs:[],
                  issuer: '',
                  type_ls:['В','Д'],
                  dataType: 'statement',
                  dateType: false,
                  date: '',
                  date2: '',
                  delivery: 'ЭЛЕКТРОННЫЙ ДОКУМЕНТ',
                  login: false,
                  hash_code: undefined,
                  input_hash: '',
                  have_hash: false,
                  have_pdf: false,
                  cur_type_ls: undefined,
                  first_log: true,
                  page: 0,
                  pages_count: 1,
                  no_payment: 0,
                  finiteUrl: ''
                  };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.RefreshData = this.RefreshData.bind(this);
    this.ChangePage = this.ChangePage.bind(this);
    this.Showall=this.Showall.bind(this);
              }

  async handleChange(event) {
    const options = { year: "numeric",
                      month: "2-digit",
                      day: "numeric"};
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "date" || name === "date2"){
      const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}doc_date/?emit=`+this.state.issuer)
      .then((response) => {return response.json()});
      var dt_end = new Date();
      localStorage.setItem('dt_end', 'текущее время')
      if (res.dt_end) {
        dt_end = new Date(res.dt_end);
        localStorage.setItem('dt_end', dt_end.toLocaleDateString("ru", options));
      }
      var dt_start = new Date(res.dt_start);
      var input_date = new Date(value);
      localStorage.setItem('dt_start', dt_start.toLocaleDateString("ru", options));
      if (dt_end <= input_date){
        document.querySelector(".bad_alert_bttn_3").click();
        target.value = undefined;
      }
      else if (dt_start >= input_date){
        document.querySelector(".bad_alert_bttn_3").click();
        target.value = undefined;
      }
      else{
        this.setState({
          [name]: value
        });
      }
    }
    else{
      this.setState({
        [name]: value
      });
    }
  }


  handleChangeRadio(event){
    this.handleChange(event);

    if (this.state.dataType === "certificate"){
      this.setState({dateType : false});
    }
    if (this.state.dataType === "statement"){
      this.setState({dateType : true});
    }
  }


  async handleSubmit(event, data) {
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    var today = new Date();
    var thisType = 'В';
    if (data['dataType'] === "certificate"){
      thisType = 'С';
    }
    var issuer = this.state.issuer;
    var type_ls_value = this.state.cur_type_ls;
    var request = {issuer:data['issuer'], dataType:data['dataType'], date:data['date'], date2:data['date2'], delivery:data['delivery'], date_create:today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), type:thisType, status:'Отправлен регистратору', hash_code:data["hash_code"], hash_date:today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), type_ls:type_ls_value};
    window.sessionStorage.request = JSON.stringify(request);

    event.preventDefault();
    if(this.checkCode()){
      this.setState({
        hash_code: undefined,
        have_hash: false,
      });
      document.getElementById("alertBttn").click();
      this.payment()    
}
  }


  async componentDidMount() {
    try {
        await this.RefreshData();
        const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
        const userRes = await fetch(`${API_ADDRESS}current_user/`, {
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`
            }
        });

        const userData = await userRes.json();
        const no_payment = userData.no_payment;
        const res = await fetch(`${API_ADDRESS}get_issuers/`, {
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`
            }
        });

        if(res["statusText"] === "Unauthorized"){
        alert("Время сессии истекло. Пожалуйста выполните вход.");
        document.getElementById("logoutBttn").click();
        }

        const issuers = await res.json();
        this.setState({
            issuers: issuers,
            no_payment: no_payment,
            issuer: issuers[0]?.id,
            cur_type_ls: issuers[0]?.type_ls_name[0],
            type_ls: issuers[0]?.type_ls
        });
    } catch (e) {
        console.log(e);
    }
}


  async RefreshData() {
    try {
      const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
      const res = await fetch(`${API_ADDRESS}inf/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      });
      console.log("RefreshData called");
      const docs = await res.json();
      var pages_qantity = Math.ceil(docs.documents.length/10);
      this.setState({
        docs: docs,
        pages_count: pages_qantity
      });

    } catch (e) {
      console.log(e);
    }
  }
   async Showall(){
    try {
      const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
      const res = await fetch(`${API_ADDRESS}full/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      });
      console.log("Full inf called");
      const docs = await res.json();
      var pages_qantity = Math.ceil(docs.documents.length/10);
      this.setState({
        docs: docs,
        pages_count: pages_qantity
      });

    } catch (e) {
      console.log(e);

    }
  }
  HavePdf(success) {
      this.setState({
        have_pdf:true
      })

  }


  resetCode(){
    this.setState({
      hash_code: undefined,
      have_hash: false
    });
  }

  ChangePage(id){
    if(id !== this.state.page){
      this.setState({
        page: id
      });
    }
  }
  async payment() {
    var request = JSON.parse(window.sessionStorage.request);
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const res=await fetch(`${API_ADDRESS}registryYoo/`, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
        body: JSON.stringify(request)
      });
        const json=await res.json();
        const url=await json['p_url'];
        window.open(url, "_self");
  }
  getCode() {
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const REACT_APP_ESIA_REDIRECT_LOGOUT_URL=process.env.REACT_APP_ESIA_REDIRECT_LOGOUT_URL;
    try{
    fetch(`${API_ADDRESS}code/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`
      }
      }).then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
          if(result !== undefined){
            this.setState({
              hash_code: result[23]+result[24]+result[25]+result[26]+result[27],
              have_hash: true
            })
          }
          else{
            window.location.reload();
          }
        });
        //console.log("INPUT HASH = " + this.state.input_hash);
      }
   catch(error)  {
                        localStorage.removeItem('token');
                        this.setState({ logged_in: false, username: '' });
                        this.setState({displayed_form:'login'});
                        clearInterval(this.timerID);
                        if(this.state.esia_in === 'true')
                        {
                            this.setState({esia_in: false})
                            localStorage.setItem('esia_login', false);
                            
                            window.location.href=`${REACT_APP_ESIA_REDIRECT_LOGOUT_URL}`;
                        };

}
   }


    checkCode() {
      if(this.state.hash_code === this.state.input_hash){
        return true;
      }
      else{
        return false;
      }
    }

    submitClick() {
      //var bttn = this.SubmitBttn.current;
      //bttn.click();
      if(this.checkCode){
        this.OnSubmitForm.current.dispatchEvent(new Event("submit"));
          }
    }

    submClick() {
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    fetch(`${API_ADDRESS}current_user/`, {
        method: 'GET',
        headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
        }
    })
    .then(response => response.json())
    .then(data => {
        if (data.no_payment === 1) {
            window.open('https://' + data.finiteUrl + '/#/successNoPayment', "_self");
        } else {
            this.submitClick();
        }
    })
    .catch(error => {
        console.error('Ошибка при получении данных о пользователе', error);
    });
}

  render() {
    var chosen_issuer = this.state.issuers.find(currentIssuer => currentIssuer.id==this.state.issuer);
    function formatDate (input) {
      var date  = input.slice(0, 10);
      if (date.toString() === "None"){
        return "";
      }
      var datePart = input.match(/\d+/g),
      year = datePart[0].substring(0), // get only two digits
      month = datePart[1], day = datePart[2];
      return day+'.'+month+'.'+year;
    }
    var date = this.state.date;
    var date2 = this.state.date2;
    var type = this.state.dataType;
    function MakeAlert(){
      //var home = document.createElement("Home");
      //var body = document.getElementsByTagName("form")[0];
      //body.appendChild(home);
      if(date === ""){
          document.querySelector(".bad_alert_bttn").click();
      }
      else {
        if(type === "certificate"){
          if(date2 === ""){
            document.querySelector(".bad_alert_bttn").click();
          }else{
            document.querySelector(".alert_bttn").click();
          }
        }
        else {
          document.querySelector(".alert_bttn").click();
        }
      }
    }


    var key = this.state.issuers[0];

   async function DownloadPDF(pdf){
      console.log(pdf)
      var request = {guid:pdf};
      const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
      const res = await fetch(`${API_ADDRESS}load_pdf_from_storage/`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });
      const answer = await res.json();
      var pdf = answer['pdf'];
      if(pdf !== undefined){
        pdf = pdf.slice(1);
        pdf = pdf.substring(0, pdf.length - 1);
        pdf = pdf.split(',');
        pdf = pdf.map(function (el) {
          return el.trim();
        });
        var byteArray = new Uint8Array(pdf);
        var pdfData = new Blob([byteArray], {type: 'application/pdf'});
        let url = window.URL.createObjectURL(pdfData);
					let a = document.createElement('a');
					a.href = url;
					a.target='_blank';
					a.click();
        }
      }

    var docs = this.state.docs['documents'];
    const options = {
        timeout: 5000,
        position: positions.TOP_CENTER,
        transition: 'scale'
    };

    if(docs === undefined){
      docs = [];
    }

    if(key !== undefined && chosen_issuer !== undefined){
      return (
        <>

        <form id="infoForm" className="infoForm" ref={this.OnSubmitForm} onSubmit={event => this.handleSubmit(event, this.state)}>
          <div className="wrapper1">
            <div className="label">
                <label className="bigLetters" htmlFor="Select">Эмитент</label>
            </div>
            <div >
                  <select id="issuer" name="issuer"  onChange={this.handleChange} >
                  {this.state.issuers.map(item => (
                      <option  key={item.id} value={item.id}>{item.issuer_name}</option>
                  ))}
                  </select>
            </div>
            <div className="label">
                <label className="bigLetters" htmlFor="Select"> Тип счёта</label>
            </div>
            <div>
                  <select id="cur_type_ls" name="cur_type_ls" onChange={this.handleLS}>
                  {this.state.issuers.find(issuer => issuer.id==this.state.issuer).type_ls_name.map(item => (
                      <option key={item}>{item}</option>
                  ))}
                  </select>
            </div>
            <div className="label">
                  <label className="bigLetters" htmlFor="radio">Тип</label>
            </div>
            <div>
              <div className="radioText">
                  <input type="radio" name="dataType" value="statement" checked={this.state.dataType === "statement"}  onChange={this.handleChangeRadio} /> Выписка
              </div>
              <div className="radioText">
                  <input type="radio" name="dataType" value="certificate"  checked={this.state.dataType === "certificate"} onChange={this.handleChangeRadio}/> Справка
              </div>
            </div>

              <div className="label">
                  <label className="bigLetters" htmlFor="date">Дата</label>
              </div>

              <div onClick={() => {
                  document.getElementById("date").blur();
                  document.getElementById("date2").blur();
              }}>

                  <div id="dates" onChange={this.handleChange}>
                      <input type="date" name="date" id="date" onFocus={(e) => e.target.blur()} tabindex="-1"/>
                      {this.state.dateType ? (
                          <input type="date" name="date2" id="date2" onFocus={(e) => e.target.blur()} tabindex="-1"/>
                      ) : (
                          <p></p>
                      )}
                  </div>
              </div>


              <div className="label">
                  <label className="bigLetters" htmlFor="Select">Способ доставки</label>
              </div>

              <div>
                  <select id="delivery" name="delivery" onChange={this.handleChange}>
                        <option value="ЭЛЕКТРОННЫЙ ДОКУМЕНТ">ЭЛЕКТРОННЫЙ ДОКУМЕНТ (ЛК АКЦИОНЕРА)</option>
                  </select>
            </div>

            </div>
              <button type="submit" id="SubmitBttn" className="SubmitBttn" style={{display: 'none' }}/>

              <div style={{display: 'none' }}>
                <Provider template={AlertTemplate} {...options}>
                <Fragment><button id="alertBttn"type="submit" className="submit buttonFont" onClick={() => MakeAlert()}> {this.state.no_payment ? 'Получить' : 'Оплатить'} </button></Fragment>
                <Home/>
                </Provider>
              </div>

              <div  className="SubmitBttn1"onClick={() => setTimeout(() => {
              this.resetCode();

            }, 120000)}>
            {this.state.dateType ? (
              <>
                  { this.state.date!=="" &&
                  <>
                    { this.state.date2!=="" &&

                          <DemoTwo onClick={this.getCode.bind(this)} callback={this.handleChange} hash={this.checkCode.bind(this)} refresh={this.RefreshData.bind(this)} childOnClick={this.submClick.bind(this)} isActive={false}/>
                    }
                  </>
                  }
                  {this.state.date !=="" &&
                    <>
                    { this.state.date2==="" &&
                      <Provider template={AlertTemplate} {...options}>
                      <Fragment><button className="SubmitBttn3 button2" id="alertBttn" onClick={() => MakeAlert()}>  {this.state.no_payment ? 'Получить' : 'Оплатить'} </button></Fragment>
                      <Home/>
                      </Provider>
                    }
                    </>

                  }
              </>
            ) :(
              <>
              { this.state.date!=="" &&

                    <DemoTwo onClick={this.getCode.bind(this)} callback={this.handleChange} hash={this.checkCode.bind(this)} refresh={this.RefreshData.bind(this)} childOnClick={this.submClick.bind(this)} isActive={false}/>
              }
              </>
            )}

            { this.state.date==="" &&
              <Provider template={AlertTemplate} {...options}>
              <Fragment><button className="SubmitBttn3 button2" id="alertBttn" onClick={() => MakeAlert()}> {this.state.no_payment ? 'Получить' : 'Оплатить'} </button></Fragment>
              <Home/>
              </Provider>
            }
            </div>
        </form>

        <div className="table-wrap">
        <button className="button2 flexButton" onClick={this.RefreshData}> Обновить </button>
	      <button className="button2 flexButton" onClick={this.Showall}> Показать все </button>
        <div className="paginationRow">
        <span className="paginationLabel"> Страница </span>
        {
          [...Array(this.state.pages_count)].map((x, i) =>
                <>
                {
                  i === this.state.page ? (
                <span className="paginationElement selectedPaginationElement" onClick={()=>{this.ChangePage(i);}}> {i+1} </span>
                  ) : (
                <span className="paginationElement" onClick={()=>{this.ChangePage(i);}}> {i+1} </span>
                )
              }
            </>
          )
        }
        </div>
        <table className="table-cust" id="customers">
        <tbody>
            <tr>
                <th> Эмитент </th>
                <th> Тип документа </th>
                <th> Дата по состоянию на </th>
                <th> Способ доставки </th>
                <th> Статус </th>
                <th> Номер документа </th>
                <th> Дата документа </th>
                <th> PDF Документ </th>
            </tr>
            {docs.slice(this.state.page*10, this.state.page*10 + 10).map((item, i) =>

              <tr key={i}>
                  <td>
                    <LazyLoad key={i} placeholder={<Loading/>}>
                      {item.issuerN}
                    </LazyLoad>
                  </td>

                  <td>
                    <LazyLoad key={item.id} placeholder={<Loading/>}>
                      {item.typeN}
                    </LazyLoad>
                  </td>

                  {item.type === "С" ? (
                    <td>
                    <LazyLoad key={item.id} placeholder={<Loading/>}>
                    {formatDate(item.date)} - {formatDate(item.date2)}
                    </LazyLoad>
                    </td>
                  ) :(
                    <td>
                    <LazyLoad key={item.id} placeholder={<Loading/>}>
                    {formatDate(item.date)}
                    </LazyLoad>
                    </td>
                  )}
                  {item.delivery === "1" ? (
                    <td>
                    <LazyLoad key={item.id} placeholder={<Loading/>}>
                      ЭЛЕКТРОННЫЙ ДОКУМЕНТ (ЛК АКЦИОНЕРА)
                      </LazyLoad>
                    </td>
                  ) : (
                    <>
                    {item.delivery === "л" ? (
                      <td>
                      <LazyLoad key={item.id} placeholder={<Loading/>}>
                        ЛИЧНО
                        </LazyLoad>
                      </td>
                    ) : (
                      <td>
                      <LazyLoad key={item.id} placeholder={<Loading/>}>
                        ПОЧТОЙ
                        </LazyLoad>
                      </td>
                    )}
                  </>
                  )}


                    <td>
                    <LazyLoad key={item.id} placeholder={<Loading/>}>
                      {item.status_name}
                      </LazyLoad>
                    </td>
                    <td>
                    <>
                      {item.no_out !== null ? (
                        <LazyLoad placeholder={<Loading/>}>
                        {item.no_out}
                        </LazyLoad>
                      ):(
                        <LazyLoad placeholder={<Loading/>}>
                        Отсутствует
                        </LazyLoad>
                      )}
                      </>
                    </td>
                    <td>
                    <>
                      {item.dt_out !== null ? (
                        <LazyLoad placeholder={<Loading/>}>
                        {formatDate(item.dt_out)}
                        </LazyLoad>
                      ):(
                        <LazyLoad placeholder={<Loading/>}>
                        Отсутствует
                        </LazyLoad>
                      )}
                      </>
                    </td>
                  <td>

                  <LazyLoad key={item.id} placeholder={<Loading/>}>
                  {item.status === 1 ? (
                    <div>
                      По техническим причинам документ не сформирован. Он будет подготовлен и отправлен в течение 5 рабочих дней
                    </div>
                  ) : (
                    <button className="downloadBttn button2" onClick={() => { DownloadPDF(item.pdf); setTimeout(() => this.RefreshData(),500)}}>
                        Скачать
                    </button>
                  )
                  }

                  </LazyLoad>
                  </td>
              </tr>
            )}
        </tbody>
        </table>
        </div>
        <ChangePassword style={'none'}/>
        </>
      );
    }
    return (
      <>
        <Loading/>
      </>
    )

  }
}
export default Info;
