import AriaModal from "react-aria-modal";
import Loading from "./loader";
import ReactTooltip from "react-tooltip";
import close from "./images/close.png"
import message from "./images/message.png"


const React = require('react');

class DemoTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalActive: this.props.isActive,
      check: false,
    };

    this.activateModal = this.activateModal.bind(this);
    this.deactivateModal = this.deactivateModal.bind(this);
    this.changeState = this.changeState.bind(this);
    this.submitCheck = this.submitCheck.bind(this);
  }
  changeState = () =>{
    this.setState({check:false});
  }

  activateModal = () => {
    this.props.onClick();
    this.setState({ modalActive: true });
    setTimeout(this.deactivateModal,120000);
  };

  deactivateModal = () => {
    this.setState({ modalActive: false });
    if (typeof this.props.closeFunc !== "undefined") {
        this.props.closeFunc();
    }
  };

  submitCheck = () =>{

    this.setState({check:true});
    this.setState({ check: true }, () => {
      console.log(this.state.check, 'state check');
    });
    setTimeout(this.deactivateModal,500);
    setTimeout(this.changeState, 504);
    setTimeout(this.props.refresh, 508);

  };

  render() {
    const modal = this.state.modalActive
      ? <AriaModal
          titleId="demo-two-title"
          onExit={this.deactivateModal}
          underlayClickExits={false}
          verticallyCenter={true}
        >

            <form>

              <div id="demo-two-modal" className="modal">
              <img className="close-bttn img" onClick={this.deactivateModal} height="35x" width="35px" align="right" src={close} alt="close"/>
                <header className="modal-header">
                  <h2 id="demo-two-title" className="modal-title">
                    Введите в поле код из Email
                  </h2>
                </header>
                <div className="modal-body">
                    <div className="col-75 raw-4">
                        <p></p>
                    </div>
                    <div className="col-25 raw-4">
                        <p></p>
                    </div>
                    <div className="col-50 raw-4">
                        <label htmlFor="Select" class="radioText">Код из Email</label>
                    </div>
                    <div className="col-50 raw-4" onChange={this.handleChange}>
                    <input type="text" name="input_hash" id="input_hash" onChange={this.props.callback} maxLength="5"/>
                    </div>

                </div>

                <footer className="modal-footer">
                {this.state.check ?
                  (
                    <>
                    <button style={{height: '5', visibility: 'hidden'}}>
                    </button>
                    <Loading/>
                    </>
                  )
                  :
                  (
                  <div>

                    {this.props.hash() ?
                      (
                        <div onClick={this.submitCheck}>
                          <button type="submit" onClick={() => this.props.childOnClick()}>
                            Отправить
                          </button>
                        </div>
                      ) : (
                        <div >
                            <a data-tip data-for='clickme' data-event='click' href="/#">
                              <button>
                                Отправить
                              </button>
                            </a>
                            <ReactTooltip id='clickme' place='right' effect='solid' clickable={true}>
                            <p> Введён не верный код</p>
                            </ReactTooltip>
                        </div>
                      )}

                </div>
              )}
              </footer>
              </div>
              </form>
        </AriaModal>
      : false;

    return (
        <div>
            <button className="SubmitBttn4 button2" onClick={this.activateModal}>
                <img className="img" height="20px" width="20px" align="left" src={message} alt="message"/>  Отправить
            </button>
            {modal}
        </div>

    );
  }
}
export default DemoTwo;
